import './App.css';

import logo from './assets/logo.png';

import srb from './assets/srb.png';
import eng from './assets/eng.png';
import { useState } from 'react';

const App = () => {
  const [language, setLanguage] = useState('eng');

  return (
    <div className='wrapper'>
      <div className='nav'>
        <img src={logo} alt='migorapul' />
        <div className='languages'>
          <img src={srb} onClick={() => setLanguage('srb')} alt='srb' />
          <img src={eng} onClick={() => setLanguage('eng')} alt='eng' />
        </div>
      </div>
      {language === 'eng' ? (
        <div className='main'>
          GZD Migorapul is a Belgrade based young and successful construction
          company whose number of satisfied clients and references is
          continuously and rapidly expanding. Our company performs construction
          masonry and finishing works on new builds, as well as reconstruction,
          adaptation and renovation on existing facilities. We employ only the
          most skilled and experienced workers and engineers with over thirty
          years of work experience in our relevant fields.
          <br />
          <br />
          This allows us not only to execute our work impeccably but
          additionally allows us to hold all of the necessary licenses and
          permissions required to perform it. Such expertise is the foundation
          of an ever, without exception, list of satisfied clients and thus the
          basis of our business growth.
          <br />
          <br />
          Our company is also a subcontractor of the renowned construction
          company Vegrap, also from Belgrade, which has been engaged for
          numerous consecutive years in a broad range of construction works on
          state owned facilities of both national importance and interests.
        </div>
      ) : (
        <div className='main'>
          GZD Migorapul je mlada i uspešna građevinska firma sa sedištem u
          Beogradu čiji se broj zadovoljnih klijenata i referenci kontinuirano i
          brzo širi. Naša firma izvodi građevinske zidarske i završne radove na
          novogradnjama, kao i rekonstrukciju, adaptaciju i obnovu postojećih
          objekata.
          <br />
          <br />
          Zapošljavamo samo najkvalitetnije i najiskusnije radnike i inženjere
          sa preko trideset godina radnog iskustva u našim relevantnim
          područjima. To nam omogućuje ne samo bespriekorno obavljanje našeg
          posla, već nam dodatno omogućuje posedovanje svih potrebnih licenci i
          dozvola potrebnih za njegovo obavljanje. Takva stručnost temelj je
          uvek, bez izuzetka, liste zadovoljnih klijenata, a time i temelj našeg
          poslovnog rasta.
          <br />
          <br />
          Naša firma je i podizvođač renomirane građevinske firme Vegrap, takođe
          iz Beograda, koja se već niz godina bavi širokim spektrom građevinskih
          radova na objektima u državnom vlasništvu od nacionalnog značaja i
          interesa.
          <br />
          <br />
          <div
            style={{
              padding: 15,
              color: '#000',
              background: '#fff',
              borderRadius: '10px',
              fontSize: '1.1em',
            }}
          >
            Preduzecu GZD MIGORAPUL d.o.o iz Beograda potrebni gradjevinski
            radnici sledecih profila:<b> MOLER, FASADER, GIPSAR.</b> Prijave
            mozete poslati na mail adresu{' '}
            <a href='mailto: office@migorapul.com'>office@migorapul.com</a>{' '}
            <br />
            Informacije na telefon <a href='tel: 0652095911'>0652095911</a>,
            radnim danima od 8 - 16h
          </div>
        </div>
      )}
      <div className='footer'>
        <div>
          {language === 'eng'
            ? 'CONSTRUCTION AND FINISHING WORKS '
            : 'GRAĐEVINSKO ZANATSKO DRUŠTVO '}
          MIGORAPUL D.O.O BEOGRAD
          <br />
          Majora Branka Vukosavljevića 8, 11070 Novi Beograd
          <br />
          PIB: 102198884{' '}
          {language === 'eng'
            ? 'Company registration number:'
            : 'Matični broj:'}{' '}
          17421808
        </div>
        <div>
          +381 65 20 95 911
          <br />
          office@migorapul.com
          <br />
          {language === 'eng' ? 'Bank account number:' : 'Tekući račun:'}{' '}
          340-0000011030514-35
          <br />
          Erste Bank
        </div>
      </div>
    </div>
  );
};

export default App;
